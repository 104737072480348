.menu-toggle {
  font-size: 24px;
  position: fixed;
  top: 15px;
  left: 15px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001;
}

.menu {
  position: fixed;
  top: 0;
  left: -291px;
  width: 250px;
  height: 100%;
  background-color: #333;
  color: white;
  padding: 20px;
  transition: left 0.3s ease;
  z-index: 1000;
  overflow-y: auto;
}

.menu.open {
  left: 0; /* Slide the menu into view */
}

.menu ul {
  list-style-type: none;
  padding: 0;
}

.menu ul li {
  margin: 20px 0;
}

.menu ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.menu ul li a:hover {
  text-decoration: underline;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
