/* Tab layout container */
.tab-layout {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  font-family: Arial, sans-serif;
}

/* Content section */
.tab-content {
  flex: 1;
  padding: 16px;
  text-align: center;
  overflow-y: auto; /* Ensure scrolling if content overflows */
}

/* Buttons fixed at the bottom */
.tab-buttons {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 14px;
  text-align: center;
  width: 100%;
  border-top: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 8px 0;
  z-index: 100; /* Ensures it stays above other content */
}

/* Button styles */
.tab-button {
  flex: 1;
  border: none;
  background: none;
  /* padding: 12px 0; */
  font-size: 16px;
  color: #666;
  outline: none;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.tab-button.active {
  color: #7c77fb;
  font-weight: bold;
  /* background-color: #eef5ff; */
}

.tab-button:hover {
  /* background-color: #f0f0f0; */
}

.tab-label {
  font-size: 14px;
}
