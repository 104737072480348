@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
body {
  margin: 0px;
  font-family: "OMNIBLACK", sans-serif;
}

p {
  font-family: "OMNIBLACK", sans-serif;
  font-weight: 200;
}

div,
h2,
h3 {
  font-family: "OMNIBLACK", sans-serif;
  font-weight: 200 !important;
}

.app-container {
  margin-left: 12px;
  margin-right: 12px;
}

.app-header {
  position: fixed;
  width: 100%;
  background: #f5f5f9;
  z-index: 9;
}

.bg {
  background-color: #f5f5f9;
}

.app-font {
  font-family: "OMNIBLACK", sans-serif;
  /* font-family: "OMNIBLACK Outline", sans-serif;
  font-family: "OMNIBLACK Outline Italic", sans-serif; */
}

.search-input {
  width: 95%;
  height: 44px;
  border-radius: 0px;
  border-color: white;
  border-width: 0px;
  font-size: 16px;
  border-radius: 10px;
  padding-left: 11px;
}

/* .search-input::placeholder {
  padding-left: 11px;
} */

.app-search {
  /* margin-right: 6px; */
}

.search-share {
  position: absolute;
  margin-top: -39px;
  right: 21px;
}

.app-title {
  display: flex;
}

.app-title-text {
  width: 85%;
}

.menu-icon {
  width: 20px;
  margin-top: 23px;
  margin-right: 10px;
  margin-left: 5px;
}

.search-icon {
  width: 20px;
  position: relative;
  margin-top: -33px;
  float: right;
  margin-right: 8px;
}

.app-categories {
  padding-top: 107px;
}

.title {
  font-size: 16px;
  color: #4a4a4a;
}

.app-title {
  font-size: 20px;
}

.grid {
  display: flex;
  margin-bottom: 12px;
}

.grid-item {
  background: white;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  text-align: center;
}

.grid-item.left {
  margin-right: 10px;
}

.grid-item-text {
  margin: 0px;
  font-size: 13px;
  color: #3d3d3d;
}

.grid-item-image {
  width: 50px;
  margin-top: 14px;
  min-height: 40px;
}

.grid-product {
  display: flex;
  margin-bottom: 12px;
}

.grid-product-item {
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

.grid-product-item-image {
  width: 100%;
  height: 140px;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.grid-product-item-image-post {
  width: 79px;
  height: 70px;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.grid-item-location {
  font-size: 12px;
  margin: 0px;
  margin-top: 5px;
  color: #717171;
}

.grid-item-price {
  font-size: 16px;
  color: #7c77fb;
  padding: 0px;

  margin-right: 10px;
  text-align: center;
  margin-top: 8px;
}

.grid-content {
  margin-left: 8px;
  margin-bottom: 8px;
  padding-top: 10px;
}

.grid-product-item.left {
  margin-right: 12px;
}

.user-icon {
  width: 25px;
  margin-top: 20px;
}

.t-a-c {
  text-align: center;
}

.primary-button {
  background-color: #7c77fb;
  color: white;
  border-radius: 10px;
  text-align: center;
}

.danger-button {
  background-color: red;
  color: white;
  border-radius: 10px;
  text-align: center;
}

.danger-button p {
  padding: 8px;
}

.primary-button p {
  padding: 8px;
}

.form-input-icon {
  position: relative;
  float: right;
  margin-top: -32px;
  margin-right: 9px;
}

.form-input-error {
  color: red;
  font-size: 10px;
  margin-left: 7px;
}

.flex {
  display: flex;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}

.back-button {
  padding: 20px;
}

.header-share {
  padding-top: 17px;
}

.screen-title {
  margin-top: 16px;
  font-size: 18px;
  width: 72%;
}

.card {
  background: white;
  border-radius: 10px;
  padding: 5px;
}

.container {
  margin-left: 12px;
  margin-right: 12px;
}

.account-mobile {
  font-size: 14px;
}

.account-role {
  font-size: 12px;
}

.p-color {
  color: #7c77fb;
}

.profile-card {
  text-align: center;
  width: 50%;
}

.mt-10 {
  margin-top: 10px;
}

.list-image {
  margin-top: 17px;
  margin-right: 10px;
}

.list-name {
  width: 85%;
}

.list-arrow {
  margin-top: 16px;
}

.form-input-select {
  width: 100%;
  border: 0px;
  padding: 13px;
  border-radius: 10px;
  background-color: white;
  background: white;
}

.form-input-file-upload {
  /* background: white; */
  width: 100%;
  padding: 11px 0px 11px 5px;
  border-radius: 10px;
}

.empty-image {
  width: 100px;
}

.loader-image {
  width: 30px;
}

.form-input-label {
  font-size: 12px;
  padding-left: 10px;
  padding-top: 10px;
}

.form-input-container {
  background: white;
  border-radius: 10px;
}

textarea {
  width: 95%;
  margin-left: 6px;
  border: none;
  height: 75px;
}

.post-title {
  font-size: 15px;
}

.post-location {
  font-size: 11px;
  color: #575656;
}

.account-header {
  position: fixed;
  width: 100%;
  background: #f5f5f9;
}

.account-content {
  padding-top: 60px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.grid-container-desktop {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

.desktop-container {
  width: 40%;
  margin-left: 30%;
}

.quick-links {
  margin: 0px;
  width: 75%;
  position: relative;
  margin-top: -15px;
}
.view-cars {
  width: 100%;
  /* height: 300px; */
}
.post-sliders {
  border-radius: 32px;
  width: 100%;
  align-items: center;
  height: 100%;
  object-fit: cover;
}
.post-price-container {
  /* display: flex;
  justify-content: space-between; */
  color: #7c77fb;
  padding-left: 10px;

  /* margin-left: 14px; */
}
.post-category {
  background: #698aff;
  padding: 6px;

  border-radius: 4px;
  font-size: 13px;
  color: #fff;
  display: inline-block;
  /* height: 30px; */
  align-items: center;
}
.date {
  color: #28282a;
  padding-right: 10px;
}
.view-product {
  font-family: sans-serif;
}
.f-bold {
  font-weight: bold;
}

.pr-10 {
  padding-right: 10px;
}
.mt-30 {
  margin-top: 30px;
}
.seller-information {
  background: #383e50;
  padding: 15px;
  border-radius: 12px;
}
.view-more-btn {
  background: #01cfb0;
  border-radius: 8px;
  padding: 0 15px;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: none;
  margin-left: 30%;
  width: 30%;
  cursor: pointer;
}
.contact-icons {
  display: flex;
  align-items: center;
}
.contact-button {
  padding: 5px 5px 1px 5px;
  border-radius: 4px;
  font-size: 15px;
  color: #fff;
  display: inline-block;
  /* height: 30px; */
  align-items: center;
}
.contact-profile {
  width: 25%;
  margin-left: "30px";
  border-radius: 12px;
  margin-top: 10px;
  height: 100px;
}
.contact {
  display: flex;
}
.contact-details {
  margin-right: auto;
  margin-left: 30px;
  font-weight: bold;
  color: #fff;
  font-size: 11px;
}

.no-image {
  width: 46%;
  height: 75px;
  padding-top: 36px;
  margin-bottom: 25px;
}

.post-price {
  font-size: 30px;
  /* margin: 13px 0px 0px 0px; */
}

.post-edit {
  font-size: 13px;
  text-align: center;
  padding: 5px 5px;
  background: #ccc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.only-mobile-content p {
  font-size: 17px;
}

.mb-10 {
  margin-bottom: 10px;
}

.color-red {
  color: red;
}

.form-input-container.error {
  border: 1px solid red;
}

.post-category2 {
  font-size: 12px;
  margin-top: 6px;
  color: #ffffff;
  background: #ab8bdf;
  text-align: center;
  border-radius: 10px;
  padding: 0px 15px;
}

.autocomplete {
  position: relative;
  display: inline-block;
}
.autocomplete-items {
  position: absolute;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-radius: 5px;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 177px;
  overflow-y: auto;
}
.autocomplete-items div {
  padding: 7px;
  cursor: pointer;
  border-radius: 5px;

  background-color: #f5f5f9;
  border-bottom: 1px solid #000;
}
.hidden {
  display: none;
  visibility: hidden;
}
/* .height-40 {
  height: 40px;
} */
.form-input-container {
  display: flex;
  flex-direction: column;
}

.text-input-icon {
  position: absolute;
  right: 10px;
  top: 60%;
  transform: translateY(-50%);
  cursor: pointer;
}

.form-input-icon {
  display: flex;
  align-items: center;
  justify-content: right;
}

.search-input-with-icon {
  width: 82%;
}
.cancel-button {
  color: red;
  border: none;
  background: transparent;
  cursor: pointer;
}
.customer-details-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  margin-top: 50px;
  padding: 20px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* .customer-info {
  display: flex;
  align-items: center;
} */

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-left: 40%;
  /* background-color: #01cfb0; */
}

.customer-info-details {
  margin-top: 22px;
}

.customer-info-item {
  margin-top: 12px;
  display: flex;
  font-size: 14px;
}

.info-label {
  /* font-weight: bold; */
  /* margin-right: 10px; */
  color: #333;
  font-size: 16px;
}

.info-value {
  color: #555;
  margin-left: 20px;

  font-size: 14px;
  word-wrap: break-word;
  white-space: normal;
  flex-grow: 1;
  line-height: 1.5;
}
.add-button {
  cursor: pointer;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  width: 30px;
  border-radius: 5px;
  height: 35px;
}

.add-button.add {
  background-color: #7c77fb;
}

.add-button.delete {
  background-color: red;
}

.grid-item-price .on-request {
  font-size: 16px !important;
  margin-top: 0px !important;
}

.on-request {
  font-size: 16px;
  margin-top: 18px;
}

.contact-seller-mode {
  margin-top: 12px;
  margin-bottom: -14px;
  text-align: center;
}

.contact-mode {
  margin-right: 10px;
}

.grid-product-item .contact-seller-mode {
  margin-bottom: 8px !important;
}

.post-desc {
  color: #676767;
  white-space: pre-line;
}

.group-buy-card {
  width: 200px;
  background: white;
  border-radius: 10px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 1px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}

.group-buy-card-image {
  width: 85px;
  height: 85px;
}

.text-color {
  color: #3d3d3d;
}

.gb-title {
  margin: 6px;
}

.gb-member-count {
  font-size: 14px;
  margin: 0px;
  color: #717171;
}

.gb-last-day {
  font-size: 14px;
  margin: 8px 0px 9px 0px;
  color: red;
}

.primary-color {
  color: #3d3d3d;
}

.secondary-color {
  color: #717171;
}

.gb-container {
  overflow-x: auto;
  white-space: nowrap;
}

.gb-no-image {
  width: 76px;
}

.gb-admin {
  margin: 3px 0px 3px 0px;
  font-size: 14px;
  color: #717171;
}

.gb-date {
  background: red;
  color: #fff;
  margin-top: -9px;
  padding: 2px 2px 2px 2px;
  position: absolute;
  width: 79px;
  font-size: 12px;
}

.gb-s-container {
  padding-top: 62px;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
}

.gb-s-header {
  background: white;
  border-radius: 10px;
}

.gb-s-header img {
  width: 100px;
  padding-left: 10px;
}

.gb-s-title {
  padding-left: 10px;
  margin: 10px 0px 5px 0px;
}

.gb-s-members-count {
  color: #717171;
  font-size: 15px;
  margin: 0px;
}

.gb-s-admin {
  color: #717171;
  font-size: 15px;
  margin: 5px 0px 0px 0px;
}

.gb-s-members-title {
  text-align: left;
  font-size: 18px;
}

.gb-join-container {
  padding-top: 25px;
  padding-left: 16px;
  padding-right: 16px;
  overflow: auto;
  padding-bottom: 25px;
}

.gb-member-card {
  background: white;
  border-radius: 10px;
  margin-bottom: 10px;
}

.gb-member-card p {
  text-align: left;
  padding: 0px 0px 0px 15px;
  margin-bottom: 8px;
}

.gb-s-desc {
  white-space: pre-line;
  font-size: 13px;
  padding-bottom: 10px;
}

.no-margin {
  margin: 0px !important;
}

.gb-share {
  padding-bottom: 7px;
}

.resend-otp {
  color: #7c77fb;
}

.app-color {
  color: #7c77fb;
}

.red-color {
  color: red;
}

.gb-s-member-address {
  font-size: 14px;
  color: #717171;
  padding: 0px 15px 1px 30px !important;
  margin: 0px !important;
}

.form-input-date {
  background: white;
  background-color: white;
}

.capture-payment {
  padding-right: 14px;
  font-size: 20px;
  padding-top: 10px;
}

.payment-container {
  padding-top: 10px;
  padding-left: 16px;
  padding-right: 16px;
}

.green-color {
  color: #01cfb0;
}

.payment-screenshot {
  width: 50px;
  height: 50px;
}

.dealer-name {
  font-size: 15px;
}

.dealer-address {
  font-size: 13px;
  margin-top: 3px;
}

.dealer-mobile {
  font-size: 13px;
  margin-top: 3px;
}

.page-content {
  padding-top: 65px;
}

.dealer-card {
  background-color: white;
  border-radius: 12px;
  padding: 8px 8px 8px 12px;
  margin: 8px 8px;
}

.dealer-info {
  margin-top: 6px;
  margin-left: 12px;
}

.dealer-address-label {
  margin-left: 12px;
  margin-top: 1px;
}
.app-cars {
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.f-18 {
  font-size: 18px;
}
.f-20 {
  font-size: 20px;
}
.seller-btn {
  padding: 10px 15px;
  background-color: #7c77fb;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  height: "20px";
  margin-left: 100px;
  margin-top: 10px;
}
.customer-input {
  width: 200px;
  height: 35px;
  border: 4px solid#7c77fb;
  border-radius: 5px;
  margin-left: 20px;
}
.mt-1 {
  margin-top: 5px;
}
.ml-60 {
  margin-left: 60px;
}

.app-user-icon {
  width: 15%;
  text-align: right;
  padding-right: 30px;
}

.search-container {
  width: 95%;
}

.faq-container {
  margin: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  padding: 10px;
  margin-left: 15px;
}

.faq-description {
  color: #525050;
  font-size: 14px;
  white-space: pre-line;
}
.faq-status {
  font-size: 12px;
  color: #7c77fb;
}
/* custom styles for the whole container */
.custom-select-container {
  /* width: 100%; */
  margin-bottom: 10px;
  border: 2px solid #7c77fb;
}
.h-auto {
  height: auto !important;
}
.w-50 {
  width: 65% !important;
}
.h-44 {
  height: 44px;
}

.faq-tag {
  border: 1px solid #ccc;
  width: fit-content;
  padding: 5px 10px 6px 10px;
  border-radius: 17px;
  float: left;
  margin-right: 10px;
}

.faq-tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Optional spacing */
}
