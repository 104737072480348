.image-container {
  text-align: center;
}

.image-container img {
  width: 120px;
  margin-top: 50px;
}

.login-title {
  text-align: center;
}

.login-container {
  margin-left: 12px;
  margin-right: 12px;
}
