.floating-button {
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #7c77fb; /* Customize as needed */
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
  z-index: 1000;
}

.floating-button:hover {
  background-color: #7c77fb; /* Customize hover color */
  transform: scale(1.1);
}
